import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'

import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpInventoryEntity from '../entity';

export default class ErpInventoryList extends DefaultController {
    tableElem: any;
    datatable: any;
    editModal: bootstrap.Modal | undefined;
    warehouses = [];
    locationMap = {}

    async init() {
        this.entity = "erp/inventories"
        this.tableElem = jQuery('.datatables-users')
        await this.getWarehouses()
        this.createTable();
        this.editModal = new bootstrap.Modal((document.querySelector("#editInventory") as HTMLElement));
        await super.init();
    }

    async getWarehouses() {
        const entities = await Utils.entity.getAll("erp/warehouses")
        this.warehouses = entities.data
    }
    getEntityData(elem: any) {
        return ErpInventoryEntity.getEntityData(elem)
    }

    bindListeners() {
        this.tableElem.delegate(".export-list", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id");
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const exportArray = [["Lager", "Lagerplatz", "Artikel", "Anzahl"]]
                data.inventoryOrderWarehouseLocations.forEach((orderWarehouseLocation: any) => {
                    orderWarehouseLocation.inventoryOrderWarehouseLocationItems.forEach((orderItem: any) => {
                        exportArray.push([
                            orderWarehouseLocation.warehouseLocation[0].warehouse[0].name,
                            orderWarehouseLocation.warehouseLocation[0].name,
                            orderItem.product[0].name,
                            orderItem.quantity
                        ])
                    })
                })
                let csv = '';
                exportArray.forEach((e) => {
                    csv += `${e.join(";")}\n`
                })
                await Utils.download(new Blob(["\ufeff", csv]), `inventur_${data.title}.csv`)
            }

        });
        this.tableElem.delegate(".export-missing-list", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id");

        });


        (document.querySelector("#editErpInventoryForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editErpInventoryForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    this.datatable.ajax.reload();
                    // @ts-ignore
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('erp.inventory.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });

        this.tableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editErpInventoryForm") as HTMLFormElement);
                Object.keys(data).forEach((key) => {
                    const elem = form.querySelector(`#erp_inventory_${key}`) as HTMLInputElement | null
                    if(elem && data[key]) {
                        elem.value = data[key]
                    }
                })
                // @ts-ignore
                this.editModal.show();
            }

        });

        this.tableElem.delegate(".delete-record", "click", async (e: any) => {const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary me-3',
                cancelButton: 'btn btn-label-secondary'
            },
            buttonsStyling: false
        })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id");
                const r = await Utils.entity.destroy(id, this.entity);
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('erp.inventory.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('erp.inventory.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });
        (document.querySelector("#addNewErpInventoryForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#addNewErpInventoryForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    elem.reset()
                    jQuery('#erp_inventory_inventoryOrderWarehouseLocations').val(null).trigger('change');
                    await this.datatable.ajax.reload();

                    const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddErpInventory") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    this.toastr.success(`${Utils.translate('erp.inventory.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        })
    }

    createTable() {
        this.datatable = this.tableElem.DataTable({
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            "ajax": {
                "type" : "GET",
                "url" : `/api/v1/erp/inventories`,
                "dataSrc":  ( json: any ) => {
                    json.data.forEach((e: any) => {
                        // @ts-ignore
                        this.locationMap[e.id] = [];
                        if (e.inventoryOrderWarehouseLocations) {
                            let warehouseLocationIds = e.inventoryOrderWarehouseLocations.map((e: { warehouseLocationId: any; }) => e.warehouseLocationId);
                            this.warehouses.forEach((warehouse) => {
                                // @ts-ignore
                                const warehouseIdMap = warehouse.warehouseLocations.map(wL => wL.id)
                                if(warehouseIdMap.every((r: any) => warehouseLocationIds.includes(r))) {
                                    // @ts-ignore
                                    this.locationMap[e.id].push(`<b>Komplettes Lager ${warehouse.name}</b>`)
                                    warehouseLocationIds = warehouseLocationIds.filter((id: any) => warehouseIdMap.indexOf(id) === -1)
                                }
                            })
                            e.inventoryOrderWarehouseLocations.forEach((wL: { warehouseLocationId: any; warehouseLocation: { name: any; }; }) => {
                                if (warehouseLocationIds.indexOf(wL.warehouseLocationId) !== -1) {
                                    // @ts-ignore
                                    this.locationMap[e.id].push(wL.warehouseLocation[0].name)
                                }
                            })
                        }
                    })


                    return json.data;
                }
            },
            columns: [
                {data: 'title'},
                {data: 'id'},
                {data: 'id'},
                {data: 'status'},
                {data: 'createdAt'},
                {data: 'id'},
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        // @ts-ignore
                        return this.locationMap[data].join(", ");
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.user[0] ? full.user[0].username : "";
                    },
                },
                {
                    targets: 3,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 4,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return new Date(Date.parse(data)).toLocaleString();
                    },
                },
                {
                    targets: 5,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let html = "";
                        html += `<div class="d-flex align-items-center">`;

                        if (full.status === "Done") {
                            html += `<a href="#" data-id="${data}" class="text-body export-list">
                                    <i class="ti ti-file-download ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="text-body export-missing-list">
                                    <i class="ti ti-file-unknown ti-sm mx-2"></i>
                                  </a>`
                        } else {
                            html +=  `<!--<a href="#" data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>!-->
                                  <a href="#" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>`
                        }
                        if (full.status === "Done" && !full.transferred) {
                            html += `<a href="#" data-id="${data}" class="text-body transfer-record">
                                    <i class="ti ti-refresh ti-sm me-2"></i>
                                  </a>`
                        }
                        html += "</div>"
                        return html;
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddErpInventory'
                    }
                }
            ]
        });
    }
}